import { FormattedMessage } from 'react-intl'
import { Spinner } from '@/components'

export const Redirecting = () => {
  return (
    <div className="space-y-12 px-48 py-72 md:p-56">
      <div className="-mb-8 inline-block w-32">
        <Spinner />
      </div>
      <FormattedMessage
        tagName="h2"
        id="REDIRECTING"
        defaultMessage="Redirecting..."
      />
    </div>
  )
}
