import { clsx } from 'clsx/lite'
import { HTMLAttributes } from 'react'

export const Skeleton = ({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={clsx(
        'inline-block h-24 w-full animate-pulse rounded-rounded bg-gray-30',
        className
      )}
      {...rest}
    />
  )
}
