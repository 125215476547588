import clsx from 'clsx'
import { Icon as IconType, IconMap } from '@/utils/icons'

export interface IconProps {
  icon: IconType
  size?: 'sm' | 'md'
}

export const Icon = ({ icon, size = 'sm' }: IconProps) => {
  return (
    <img
      src={IconMap[icon]}
      alt={icon}
      className={clsx(
        size === 'sm' && 'w-[20px]',
        size === 'md' && 'w-24',
        'flex-shrink-0'
      )}
    />
  )
}
