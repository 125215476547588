import { EmbedInstance } from '@gr4vy/embed-react'
import { useRouteContext } from '@tanstack/react-router'
import clsx from 'clsx'
import { CSSProperties, RefObject } from 'react'
import { useIntl } from 'react-intl'
import { Button, Heading, Icon, Embed } from '@/components'
import { getContextFromLocation, formatCurrency, EmbedEvent } from '@/utils'
const { gr4vyId, environment } = getContextFromLocation(window.location)

export interface PayWithProps {
  embedRef: RefObject<EmbedInstance>
  handleEmbedSubmit: () => void
  handleEmbedEvent: <E extends keyof EmbedEvent>(
    event: E,
    data: EmbedEvent[E]
  ) => void
}

export const PayWith = ({
  embedRef,
  handleEmbedSubmit,
  handleEmbedEvent,
}: PayWithProps) => {
  const intl = useIntl()
  const payWith = intl.formatMessage({
    id: 'PAY_WITH',
    defaultMessage: 'Pay with',
  })
  const termsAndConditions = intl.formatMessage({
    id: 'TERMS_CONDITIONS',
    defaultMessage: 'Terms and conditions',
  })
  const apply = intl.formatMessage({
    id: 'APPLY',
    defaultMessage: 'apply',
  })
  const pay = intl.formatMessage({
    id: 'PAY',
    defaultMessage: 'Pay',
  })

  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  if (!paymentLink) return null

  const {
    amount,
    buyer,
    cartItems,
    country,
    currency,
    embedToken,
    externalIdentifier,
    intent,
    merchantTermsAndConditionsUrl,
    merchantColor,
    metadata,
    paymentSource,
    statementDescriptor,
  } = paymentLink

  return (
    <section
      className="hidden flex-col gap-24 md:flex md:basis-1/2 md:p-32"
      style={
        {
          '--color-merchant': merchantColor || 'hsl(199, 80%, 45%)',
        } as CSSProperties
      }
    >
      <div className="flex flex-col gap-8">
        <Heading icon="wallet">{payWith}</Heading>
        <Embed
          forwardedRef={embedRef}
          gr4vyId={gr4vyId}
          environment={
            ['sandbox', 'development'].includes(environment)
              ? 'sandbox'
              : 'production'
          }
          amount={amount}
          buyer={buyer}
          country={country}
          currency={currency}
          externalIdentifier={externalIdentifier}
          intent={intent}
          metadata={metadata}
          paymentSource={paymentSource}
          statementDescriptor={statementDescriptor}
          merchantAccountId="default"
          cartItems={cartItems}
          token={embedToken}
          onEvent={handleEmbedEvent}
        />
      </div>
      {merchantTermsAndConditionsUrl && (
        <section id="terms" className="flex">
          <a
            href={merchantTermsAndConditionsUrl}
            className={clsx('text-[--color-merchant]', 'inline font-bold')}
          >
            {termsAndConditions}
          </a>
          <span>&nbsp;{apply}</span>
        </section>
      )}
      <Button onClick={handleEmbedSubmit}>
        <div className="flex items-center gap-8">
          <Icon icon="lock" />
          <span>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            {pay}{' '}
            {formatCurrency(amount, {
              currency,
            })}
          </span>
        </div>
      </Button>
    </section>
  )
}
