export const Spinner = () => {
  return (
    <div className="animate-spin-steps">
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.25 10.625C6.59518 10.625 6.875 10.3452 6.875 10C6.875 9.65482 6.59518 9.375 6.25 9.375V10.625ZM2.5 9.375C2.15482 9.375 1.875 9.65482 1.875 10C1.875 10.3452 2.15482 10.625 2.5 10.625V9.375ZM6.25 9.375H2.5V10.625H6.25V9.375Z"
          fill="currentColor"
          fillOpacity="0.4"
        />
        <path
          d="M17.5 10.625C17.8452 10.625 18.125 10.3452 18.125 10C18.125 9.65482 17.8452 9.375 17.5 9.375V10.625ZM13.75 9.375C13.4048 9.375 13.125 9.65482 13.125 10C13.125 10.3452 13.4048 10.625 13.75 10.625V9.375ZM17.5 9.375H13.75V10.625H17.5V9.375Z"
          fill="currentColor"
          fillOpacity="0.8"
        />
        <path
          d="M9.375 6.25C9.375 6.59518 9.65482 6.875 10 6.875C10.3452 6.875 10.625 6.59518 10.625 6.25H9.375ZM10.625 2.5C10.625 2.15482 10.3452 1.875 10 1.875C9.65482 1.875 9.375 2.15482 9.375 2.5H10.625ZM10.625 6.25V2.5H9.375V6.25H10.625Z"
          fill="currentColor"
        />
        <path
          d="M9.375 17.5C9.375 17.8452 9.65482 18.125 10 18.125C10.3452 18.125 10.625 17.8452 10.625 17.5H9.375ZM10.625 13.75C10.625 13.4048 10.3452 13.125 10 13.125C9.65482 13.125 9.375 13.4048 9.375 13.75H10.625ZM10.625 17.5V13.75H9.375V17.5H10.625Z"
          fill="currentColor"
          fillOpacity="0.6"
        />
        <path
          d="M7.79033 13.0933C8.03441 12.8492 8.03441 12.4535 7.79033 12.2094C7.54625 11.9653 7.15052 11.9653 6.90645 12.2094L7.79033 13.0933ZM4.2548 14.8611C4.01072 15.1052 4.01072 15.5009 4.2548 15.745C4.49887 15.989 4.8946 15.989 5.13868 15.745L4.2548 14.8611ZM6.90645 12.2094L4.2548 14.8611L5.13868 15.745L7.79033 13.0933L6.90645 12.2094Z"
          fill="currentColor"
          fillOpacity="0.5"
        />
        <path
          d="M12.2098 6.90669C11.9657 7.15077 11.9657 7.5465 12.2098 7.79057C12.4539 8.03465 12.8496 8.03465 13.0937 7.79057L12.2098 6.90669ZM15.7453 5.13892C15.9894 4.89485 15.9894 4.49912 15.7453 4.25504C15.5012 4.01096 15.1055 4.01096 14.8614 4.25504L15.7453 5.13892ZM13.0937 7.79057L15.7453 5.13892L14.8614 4.25504L12.2098 6.90669L13.0937 7.79057Z"
          fill="currentColor"
          fillOpacity="0.9"
        />
        <path
          d="M13.0933 12.2104C12.8492 11.9663 12.4535 11.9663 12.2094 12.2104C11.9653 12.4545 11.9653 12.8502 12.2094 13.0943L13.0933 12.2104ZM14.8611 15.7459C15.1052 15.99 15.5009 15.99 15.745 15.7459C15.989 15.5019 15.989 15.1061 15.745 14.8621L14.8611 15.7459ZM12.2094 13.0943L14.8611 15.7459L15.745 14.8621L13.0933 12.2104L12.2094 13.0943Z"
          fill="currentColor"
          fillOpacity="0.7"
        />
        <path
          d="M6.90669 7.79057C7.15077 8.03465 7.5465 8.03465 7.79057 7.79057C8.03465 7.5465 8.03465 7.15077 7.79057 6.90669L6.90669 7.79057ZM5.13892 4.25504C4.89485 4.01096 4.49912 4.01096 4.25504 4.25504C4.01096 4.49912 4.01096 4.89485 4.25504 5.13892L5.13892 4.25504ZM7.79057 6.90669L5.13892 4.25504L4.25504 5.13892L6.90669 7.79057L7.79057 6.90669Z"
          fill="currentColor"
          fillOpacity="0.3"
        />
      </svg>
    </div>
  )
}
