import { createRootRouteWithContext, Outlet } from '@tanstack/react-router'
import React, { Suspense } from 'react'
import { Layout } from '@/components'
import { PaymentLink } from '@/utils'

const TanStackRouterDevtools =
  import.meta.env.PROD || import.meta.env.TEST
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        }))
      )

export interface PaymentLinkContext {
  paymentLink?: PaymentLink
}

export const Route = createRootRouteWithContext<PaymentLinkContext>()({
  component: () => (
    <Layout>
      <Outlet />
      <Suspense>
        <TanStackRouterDevtools />
      </Suspense>
    </Layout>
  ),
})
