import { Icon, IconProps } from '@/components/Icon'
import { Icon as IconType } from '@/utils/icons'

export interface HeadingProps {
  children: string
  icon: IconType
  iconSize?: IconProps['size']
}

export const Heading = ({ children, icon, iconSize }: HeadingProps) => (
  <div className="flex min-w-none items-center gap-8 font-bold">
    <Icon icon={icon} size={iconSize} />
    <h4 className="truncate py-4" title={children}>
      {children}
    </h4>
  </div>
)
