import { useEffect, useState } from 'react'
import defaultMessages from '@/locales/en.json'

export interface MessagesJsonImport {
  default: typeof import('@/locales/en.json')
}

export const defaultLocale = 'en'

export const fetchMessages = async (
  locale: string,
  fetchFallback = false
): Promise<MessagesJsonImport['default']> => {
  try {
    const messages = (await import(
      `@/locales/${locale}.json`
    )) as MessagesJsonImport
    return messages?.default
  } catch (error) {
    if (fetchFallback) {
      throw new Error(String(error))
    }
    return fetchMessages(locale?.split('-')[0], true)
  }
}

export interface UseMessagesProps {
  locale: string
}

export const useMessages = (props: UseMessagesProps) => {
  const [messages, setMessages] = useState<
    Record<string, typeof defaultMessages>
  >({
    [defaultLocale]: defaultMessages,
  })
  const locale = props.locale.toLowerCase()

  useEffect(() => {
    const loadMessages = async () => {
      const fetchedMessages = await fetchMessages(locale)
      setMessages({
        ...messages,
        [locale]: { ...defaultMessages, ...fetchedMessages },
      })
    }
    if (locale !== defaultLocale) {
      loadMessages().catch(console.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  return { defaultLocale, messages }
}
