import { useRouteContext } from '@tanstack/react-router'
import { BillingShippingSection } from './BillingShippingSection'
import { Note } from './Note'
import { OrderSection } from './OrderSection'

export const Order = () => {
  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  if (!paymentLink) return null

  const { id, expiresAt, cartItems, amount, currency, buyer, merchantMessage } =
    paymentLink
  const billingDetailsAddress = buyer?.billingDetails?.address
  const shippingDetailsAddress = buyer?.shippingDetails?.address

  return (
    <div className="flex min-w-none flex-col gap-40 px-16 py-24 md:basis-1/2 md:p-32">
      <OrderSection
        id={id}
        cartItems={cartItems}
        expiresAt={expiresAt}
        amount={amount}
        currency={currency}
      />
      {(billingDetailsAddress ?? shippingDetailsAddress) && (
        <BillingShippingSection
          billingDetailsAddress={billingDetailsAddress}
          shippingDetailsAddress={shippingDetailsAddress}
        />
      )}
      {merchantMessage && <Note message={merchantMessage} />}
    </div>
  )
}
