import camelCaseKeys from 'camelcase-keys'

export const createTransform =
  (
    transformFn: (data: Record<string, unknown>) => Record<string, unknown>,
    skipValues:
      | string[]
      | ((data: Record<string, unknown>) => Record<string, unknown>)[] = []
  ) =>
  (data: Record<string, unknown>) => {
    const skipData = skipValues.reduce((acc, value) => {
      const skipValue = { ...acc }

      if (typeof value === 'function') {
        Object.assign(skipValue, value(data))
      } else if (data?.[value]) {
        const keyData = transformFn({ [value]: {} })
        const newKey = Object.keys(keyData)[0]
        keyData[newKey] = data[value]
        Object.assign(skipValue, keyData)
      }

      return skipValue
    }, {})
    return { ...transformFn(data), ...skipData }
  }

export const toCamel = createTransform(
  (data) => camelCaseKeys(data, { deep: true }),
  ['metadata']
)
