import { ReactNode } from 'react'

export interface PaymentLayoutProps {
  children: ReactNode
}

export const PaymentStatusLayout = ({ children }: PaymentLayoutProps) => (
  <div className="max-h-full space-y-12 px-48 py-56 md:p-56 md:pt-24">
    <div className="flex flex-col gap-32">{children}</div>
  </div>
)
