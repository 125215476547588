import { createRouter, RouterProvider } from '@tanstack/react-router'
import { NotFound } from '@/components'
import { LocaleProvider } from '@/providers'
import { routeTree } from './routeTree.gen'
import './App.css'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <NotFound />,
})

export const App = () => (
  <LocaleProvider>
    <RouterProvider router={router} />
  </LocaleProvider>
)
