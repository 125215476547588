import { useIntl } from 'react-intl'
import { Heading } from '@/components/Heading'
import { formattedAddress } from '@/utils/format-address'
import { is } from '@/utils/is'
import { BillingDetails, ShippingDetails } from '@/utils/types'

export interface BillingShippingSectionProps {
  billingDetailsAddress: BillingDetails['address']
  shippingDetailsAddress: ShippingDetails['address']
}

export const BillingShippingSection = ({
  billingDetailsAddress,
  shippingDetailsAddress,
}: BillingShippingSectionProps) => {
  const intl = useIntl()
  const billingShipping = intl.formatMessage({
    id: 'BILLING_SHIPPING',
    defaultMessage: 'Billing & shipping',
  })
  const billing = intl.formatMessage({
    id: 'BILLING',
    defaultMessage: 'Billing',
  })
  const shipping = intl.formatMessage({
    id: 'SHIPPING',
    defaultMessage: 'Shipping',
  })
  const hasOnlyBillingAddress = !!(
    billingDetailsAddress && !shippingDetailsAddress
  )
  const hasOnlyShippingAddress = !!(
    shippingDetailsAddress && !billingDetailsAddress
  )
  const hasBothAddresses = billingDetailsAddress && shippingDetailsAddress
  const isSameAddress =
    hasBothAddresses &&
    is.objectEqual(billingDetailsAddress, shippingDetailsAddress)

  return (
    <div className="flex flex-col gap-16 text-left">
      {hasOnlyBillingAddress && <Heading icon="billing">{billing}</Heading>}
      {hasOnlyShippingAddress && (
        <Heading icon="shipping" iconSize="md">
          {shipping}
        </Heading>
      )}
      {(hasOnlyBillingAddress || hasOnlyShippingAddress) && (
        <p>
          {formattedAddress(billingDetailsAddress ?? shippingDetailsAddress)}
        </p>
      )}
      {hasBothAddresses && isSameAddress && (
        <>
          <Heading icon="shipping">{billingShipping}</Heading>
          <p>{formattedAddress(billingDetailsAddress)}</p>
        </>
      )}
      {hasBothAddresses && !isSameAddress && (
        <div className="flex flex-col gap-40">
          <div className="flex flex-col gap-16">
            <Heading icon="billing">{billing}</Heading>
            <p>{formattedAddress(billingDetailsAddress)}</p>
          </div>
          <div className="flex flex-col gap-16">
            <Heading icon="shipping" iconSize="md">
              {shipping}
            </Heading>
            <p>{formattedAddress(shippingDetailsAddress)}</p>
          </div>
        </div>
      )}
    </div>
  )
}
