export const getContextFromLocation = (location: Location) => {
  const { host: url, pathname } = location
  const paymentLinkId = pathname.split('/').pop()
  let gr4vyId = 'dev'
  let environment: 'sandbox' | 'production' | 'development' = 'development'

  if (url.match(/pay(\.sandbox|)\..*\.gr4vy\.app/g)) {
    const host = url.split('.').reverse()
    gr4vyId = host[2]

    if (host[2] !== 'dev') {
      environment = host[3] === 'sandbox' ? 'sandbox' : 'production'
    }
  }

  return { gr4vyId, environment, paymentLinkId }
}
