import billing from '@/assets/images/icons/billing.svg'
import fileNote from '@/assets/images/icons/file-note.svg'
import securityLock from '@/assets/images/icons/security-lock.svg'
import shipping from '@/assets/images/icons/shipping.svg'
import shoppingBasket from '@/assets/images/icons/shopping-basket.svg'
import wallet from '@/assets/images/icons/wallet.svg'

export const IconMap = {
  order: shoppingBasket,
  billing: billing,
  shipping: shipping,
  wallet: wallet,
  lock: securityLock,
  note: fileNote,
} as const

export type Icon = keyof typeof IconMap
