import { useRouteContext } from '@tanstack/react-router'
import clsx from 'clsx'

export const MerchantLogo = () => {
  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  const merchantBannerUrl = paymentLink?.merchantBannerUrl
  const merchantName = paymentLink?.merchantName

  return (
    <div
      className={clsx(
        merchantBannerUrl && 'pb-16 pt-56',
        'flex w-full items-center justify-center'
      )}
    >
      {merchantBannerUrl && (
        <img
          src={merchantBannerUrl}
          alt={merchantName}
          title={merchantName}
          className="w-full max-w-logo-sm md:max-w-logo-md"
        />
      )}
    </div>
  )
}
