const emptyObject = (value: Record<string, unknown> | undefined) => {
  if (!value || !plainObject(value)) {
    return true
  }
  return Object.keys(value).length === 0
}

const emptyString = (value: unknown) => string(value) && value.length === 0

const emptyStringOrWhitespace = (value: unknown) =>
  string(value) && emptyString(value.trim())

const plainObject = (value: unknown) =>
  Object.prototype.toString.call(value) === '[object Object]'

const string = (value: unknown) => typeof value === 'string'

const objectEqual = (
  obj1: Record<string, unknown>,
  obj2: Record<string, unknown>
) => {
  const entries1 = Object.entries(obj1)
  const entries2 = Object.entries(obj2)

  if (entries1.length !== entries2.length) return false

  return entries1.every(([key, value]) => obj2[key] === value)
}

export const is = {
  array: (value: unknown) => Array.isArray(value),
  boolean: (value: unknown) => value === true || value === false,
  emptyObject,
  emptyString,
  emptyStringOrWhitespace,
  null: (value: unknown) => value === null,
  plainObject,
  string,
  undefined: (value: unknown) => typeof value === 'undefined',
  objectEqual,
}
