import isoCurrencies from './currencies-iso-4217.json'

export interface Currency {
  code: string
  number: string
  digits: number
  currency: string
}

export const allCurrencies = [...isoCurrencies].sort((a, b) =>
  a.code > b.code ? 1 : -1
)

export const getCurrencyByCode = (currencyCode: string): Currency | undefined =>
  allCurrencies.find(({ code }) => code === currencyCode)
