import { PropsWithChildren } from 'react'
import { IntlProvider } from 'react-intl'
import { useMessages } from './use-messages'

export const LocaleProvider = ({ children }: PropsWithChildren) => {
  const locale = 'en'
  const { defaultLocale, messages } = useMessages({ locale })

  return (
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  )
}
