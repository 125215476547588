import * as Sentry from '@sentry/react'
import { getContextFromLocation } from './get-context-from-location'

export const initSentry = () => {
  const { gr4vyId, environment, paymentLinkId } = getContextFromLocation(
    window.location
  )

  Sentry.setContext('Payment Link', {
    id: paymentLinkId,
  })

  Sentry.init({
    dsn: 'https://9ff3b4bb698972ba908c6ad0bfc6bcb3@o914254.ingest.us.sentry.io/4508636754477056',
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: `${gr4vyId}-${environment}`,
    release: RELEASE,
    ignoreErrors: [
      /^Non-Error promise rejection captured with value: undefined$/,
      /^undefined is not an object (evaluating 'ceCurrentVideo.currentTime')$/,
      /^Object captured as promise rejection with keys/,
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      const event = hint?.event as Event & { target?: HTMLElement }
      if (breadcrumb?.category?.startsWith('ui') && event?.target) {
        const innerText = event.target.innerText
        if (innerText) {
          breadcrumb.data = {
            innerText,
          }
        }
      }
      return breadcrumb
    },
  })
}
