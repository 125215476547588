import { Icon } from '@/components/Icon'

export interface NoteProps {
  message: string
}

export const Note = ({ message }: NoteProps) => (
  <div className="flex w-full items-start gap-8 text-left text-gray-90">
    <Icon icon="note" />
    <span title={message} className="line-clamp-2">
      {message}
    </span>
  </div>
)
