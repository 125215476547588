import { useRouteContext } from '@tanstack/react-router'
import { FormattedMessage } from 'react-intl'
import circleStrike from '@/assets/images/icons/circle-strike.svg'
import { PaymentStatusLayout } from '@/components/PaymentStatusLayout'

export const PaymentExpired = () => {
  const { paymentLink } = useRouteContext({ from: '/link/$id' })
  if (!paymentLink) return null

  const { merchantUrl, merchantName } = paymentLink

  return (
    <PaymentStatusLayout>
      <div className="flex flex-col items-center gap-8 md:gap-24">
        <img src={circleStrike} className="inline-block w-32" />
        <h2 className="pb-none">
          <FormattedMessage
            id="PAYMENT_EXPIRED"
            defaultMessage="Payment expired"
          />
        </h2>
      </div>
      {merchantUrl && merchantName && (
        <a href={merchantUrl}>
          <FormattedMessage
            id="RETURN_TO"
            tagName="span"
            defaultMessage="Return to"
          />
          <span className="font-bold"> {merchantName}</span>
        </a>
      )}
    </PaymentStatusLayout>
  )
}
