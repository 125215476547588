import { getContextFromLocation } from './get-context-from-location'
import { toCamel } from './transform'
import { PaymentLink } from './types'

const { gr4vyId, environment } = getContextFromLocation(window.location)

export const baseUrl =
  import.meta.env.VITE_PAYMENT_LINKS_API_URL ||
  `https://api${['sandbox', 'development'].includes(environment) ? '.sandbox' : ''}.${gr4vyId}.gr4vy.app`

export const fetchPaymentLink = async (id: string) => {
  const response = await fetch(`${baseUrl}/payment-links/${id}/token`)
  if (!response.ok) return null
  return response.json().then(toCamel) as Promise<PaymentLink>
}
