import { FormattedMessage } from 'react-intl'
import circleWarning from '@/assets/images/icons/circle-warning.svg'

export const NotFound = () => {
  return (
    <div className="space-y-12 px-48 py-72 md:p-56">
      <img src={circleWarning} className="inline-block" />
      <FormattedMessage
        tagName="h2"
        id="NOT_FOUND"
        defaultMessage="That didn't seem right."
      />
      <FormattedMessage
        tagName="p"
        id="NOT_FOUND_DESCRIPTION"
        defaultMessage="The page you are looking for doesn't exist or another error occurred."
      />
    </div>
  )
}
