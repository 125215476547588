import { getCurrentLocale } from './get-current-locale'

export const formatDate = (
  rawDate: string,
  options: Intl.DateTimeFormatOptions
) => {
  const date = new Date(rawDate)

  return new Intl.DateTimeFormat(getCurrentLocale(), options).format(date)
}
