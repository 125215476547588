import type { BillingDetails } from './types'

export const formattedAddress = (address: BillingDetails['address']) =>
  address
    ? [
        address.line1,
        address.line2,
        address.city,
        address.state,
        address.postalCode,
        address.country,
      ]
        .filter(Boolean)
        .join(', ')
    : null
