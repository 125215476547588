import { getCurrencyByCode } from './get-currency-by-code'
import { getCurrentLocale } from './get-current-locale'
import { is } from './is'

type Options = Intl.NumberFormatOptions & {
  digits?: number
  roundingIncrement?: number
}

export const formatCurrency = (amount: number, options?: Options) => {
  if (
    is.null(amount) ||
    is.undefined(amount) ||
    is.emptyStringOrWhitespace(amount)
  ) {
    return amount
  }
  const { currency, digits, ...rest } = {
    ...options,
    currency: options?.currency ?? 'USD',
  }
  const { digits: currencyDigits = 2 } = getCurrencyByCode(currency) ?? {}
  // Check if the current number of digits exceeds the number
  // of digits allowed by the currency.This is used by inputs
  // while typing, to allow rounding of the subunit portion.
  const outOfRangeDigits = digits && digits > currencyDigits
  return new Intl.NumberFormat(getCurrentLocale(), {
    style: 'currency',
    currency,
    minimumFractionDigits: currencyDigits,
    maximumFractionDigits: currencyDigits,
    roundingIncrement: 1,
    currencyDisplay: 'narrowSymbol',
    ...rest,
  }).format(amount / 10 ** (outOfRangeDigits ? digits : currencyDigits))
}
